import React from 'react';
import {  Layout, Menu, Button } from "antd";
import {
    UserOutlined, HomeOutlined 
  } from "@ant-design/icons";
  import { useAuth } from '../../context/auth.context';
  import { useNavigate, Link } from 'react-router-dom';
    import "./MyLayout.css"
  const {  Content, Header  } = Layout;


const MyLayout = (props) => {
    const {user,saveRole,saveUser,saveToken,dominio,saveSucursal,sucursal} = useAuth()
    const navigate = useNavigate()
    const salir = () =>{
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('role')
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('dominio')
        window.localStorage.removeItem('sucursal')
        saveUser(null)
        saveRole(null)
        saveToken(null)
        saveSucursal(null)
        navigate("/login")
      }

   const items = [
        getItem("Ahorro", "1", <></>, [
          getItem(<Link to="/ahorro">Ahorro Programado</Link>,"2",<></>),
        ]),
        getItem("Planes", "3", <></>, [
          getItem(<Link to="/planes">Planes</Link>,"4",<></>),
        ]),

        getItem("Reservas", "5", <></>, [
          getItem(<Link to="/bonos">Bonos</Link>,"6",<></>),
        ]),
        getItem(`${user.nombre}`, "7", <UserOutlined />, [
          getItem(<Button onClick={salir}>Salir</Button>,"8",<UserOutlined />),
        ]),

        getItem(`${sucursal}`, "9", <HomeOutlined  />, [
        
        ]),
        
      ]

      function getItem(label, key, icon, children) {
        return {
          key,
          icon,
          children,
          label,
        };
      }
      
    return (
        <Layout
        style={{
          minHeight: "100vh",
        }}
      >
      
  
        <Layout className="site-layout">
          
        <Header>
        <div > <img className="logo"  src="../assets/img/logo.png" alt="" /> </div>
  
   <Menu mode='horizontal' items={items} theme="dark" />
   </Header>

          <Content
            style={{
              margin: "0 16px",
              overflowX:'scroll',
            }}
          >
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 360,
              }}
            >
           {props.children}
            </div>
          </Content>
        </Layout>
      </Layout>
    );
};

export default MyLayout;