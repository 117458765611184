import React, { useEffect, useState } from 'react';
import MyLayout from '../../components/shared/MyLayout';
import ApiService from '../../services/api.service';
import { Table, Tooltip, Button,  Input, Modal, Spin, message } from 'antd';
import {StepForwardOutlined, SearchOutlined, EyeOutlined} from '@ant-design/icons';
import moment from 'moment'
const Bono = () => {

    const [bonos,setBonos] = useState()
    const [bonoSelected,setBonoSelected] = useState()
    const [viewInfoBono,setViewInfoBono] = useState(false)
    const [loading,setLoading] = useState(false)
    const api = ApiService()


    const columns = [

        {
            title: "No. Bono",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
                return <Input autoFocus placeholder='Ingrese No. Bono' 
                value = {selectedKeys[0]} 
                onChange={(e)=>{
                  setSelectedKeys(e.target.value?[e.target.value]:[])
                 confirm({closeDropdown:false})
                }}
                onPressEnter={()=>{
                  confirm()
                }} 
                
                onBlur={()=>{
                  confirm()
                }} >
                
                </Input>
              },
              filterIcon:()=>{
                return <SearchOutlined />
              },
              onFilter:(value,record)=>{
                let nombre = record.id.toString()
                return nombre.includes(value.toLowerCase())
              },
            render:(record) => <div>{record.id}</div>
        },

        {
            title: "Cliente",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
                return <Input autoFocus placeholder='Ingrese Nombre de Cliente' 
                value = {selectedKeys[0]} 
                onChange={(e)=>{
                  setSelectedKeys(e.target.value?[e.target.value]:[])
                 confirm({closeDropdown:false})
                }}
                onPressEnter={()=>{
                  confirm()
                }} 
                
                onBlur={()=>{
                  confirm()
                }} >
                
                </Input>
              },
              filterIcon:()=>{
                return <SearchOutlined />
              },
              onFilter:(value,record)=>{
                let nombre = `${record.cliente.nombres} ${record.cliente.apellidos}`
                return nombre.toLowerCase().includes(value.toLowerCase())
              },
            render:(record) => <div>{record.cliente.nombres} {record.cliente.apellidos}</div>
        },


        {
            title: "Fecha",
            render:(record) => <div>{moment(record.fechaCompra).format('DD-MM-YYYY')}</div>
        },

        {
            title: "Total Pagos",
            render:(record) => <div>{formatterPeso.format(calcularPagos(record))}</div>
        },

        {
            title: "Total Redimido",
            render:(record) => <div>{formatterPeso.format(calcularRedimido(record))}</div>
        },

        {
            title: "Redimio a Reserva",
            render:(record) => <div>{calcularRedimidoReservas(record)}</div>
        },

        {
            title: "Saldo a favor",
            render:(record) => <div>{formatterPeso.format(calcularPagos(record) - calcularRedimido(record))}</div>
        },

        {
            title: "Contrato",
            render:(record) => <div>{record.contrato}</div>
        },

        {
            title: "Observaciones",
            render:(record) => <div className='textScroll'>{record.observaciones}</div>
        },


        {
            title: "Estado",
            render:(record) => <div>{record.estado}</div>
        },

        {
            title: "Opciones",
            render:(record) => <div>
 <Button onClick={()=>{verBono(record)}}><Tooltip title="Información de Bono"><EyeOutlined /> </Tooltip></Button>
            </div>
        },


        
    ]

    const columnsPagos = [
        {
            title:"No. Recibo",
            dataIndex:'id'
        },
        {
            title:"Fecha de Pago",
            render:(record) => <div>{moment(record.fechaPago).format('DD-MM-YYYY')}</div>
        },

        {
            title:"Valor Pago Pesos",
            render:(record) => <div>{formatterPeso.format(record.valorPago)}</div>
        },

        {
            title:"Valor Pago Dolares",
            render:(record) => <div>{formatterPeso.format(record.valorPagoUSD)}</div>
        },

        {
            title:"Forma de Pago",
            render:(record) => <div>{record.formaPago}</div>
        },
        // {
        //     title:"Responsable",
        //     render:(record) => <div>{record.responsable}</div>
        // },

        {
            title:"Estado",
            render:(record) => {
                if(record.estado == 1){
                    return <div className='creado'>Creado</div>
                }
                    if(record.estado == 2){
                        return <div className='aprobado'>Aprobado</div>
                    }

                    if(record.estado == 3){
                        return <div className='anulado'>Anulado</div>
                    }
                
            }
        }
    ]


    useEffect(()=>{
        listarBonos()
      },[])

      const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })

      const listarBonos = async () =>{
      
        setLoading(true)
        await api.get('ventabono').then(res=>{
            console.log(res.data)
              setBonos(res.data)
              setLoading(false)
          })
      }


      const verBono = (record) =>{
        setViewInfoBono(true)
        setBonoSelected(record)
      }

      const calcularPagos = (item) =>{
        let total=0;
        if(item.pagos){
          for(let pago of item.pagos){
            if(pago.estado==2){
            total+=parseFloat(pago.valorPago);
            }
          }
    
        }
        return total;
      
      }

      const calcularRedimido = (item) =>{
        let total=0;
        if(item.egresos){
          for(let pago of item.egresos){
              total+=parseFloat(pago.valor);
    
          }
    
        }
        return total;
      }

      const calcularRedimidoReservas = (item) =>{
        let total="";
        if(item.egresos){
          for(let pago of item.egresos){
              total+=pago.reserva_id;
    
          }
    
        }
        return total;
      }
  

    return (
        <MyLayout>
              <Table scroll={{x:1200}} dataSource={bonos} columns = {columns} pagination={{ pageSize: 5 }} loading={loading} ></Table>


              { viewInfoBono && (
                <Modal width={1024} visible={viewInfoBono} onCancel={()=>{setViewInfoBono(false)}}>
                    <Table dataSource={bonoSelected.pagos} columns={columnsPagos}></Table>
                </Modal>
              )

              }
        </MyLayout>
    );
};

export default Bono;