import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../context/auth.context'
export function ProtectedRoute({children}) {
    const {user} = useAuth()
    console.log(user)
    if(!user) return <Navigate to="/login" />
  return (
    <>{children}</>
  )
}
