import React, { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom'
import MyLayout from '../../components/shared/MyLayout';
import {  Card,Table, Button, Modal, Form, Input, Select, Spin } from "antd";
import moment from "moment"
import ApiService from '../../services/api.service';
import { ConvertidorNL } from '../../utils/NumeroALetras';

import "./CostoPlan.css"
const { TextArea } = Input;
const CostoPlan = () => {
    let location = useLocation();
    let myPlan = location.state.plan

  
    const api = ApiService()
    const [loading,setLoading] = useState(false)
    const [costos,setCostos] = useState([])
    const [pagos,setPagos] = useState([])
    const [proveedores,setProveedores] = useState([])
    const [viewModalNewCosto,setViewModalNewCosto] = useState(false)
    const [viewEditModalCosto,setViewEditModalCosto] = useState(false)
    const [costoSelected,setCostoSelected] = useState()
    const [formCosto] = Form.useForm()
    const [formEditCosto] = Form.useForm()
    const [viewModalNewPago,setViewModalNewPago] = useState(false)
    const [formPago] = Form.useForm()
    const convertidor = new ConvertidorNL()

    useEffect(()=>{
        listarCostos()
        listarPagos()

        listarProveedores()
    },[])

    const goModalNewCosto = () =>{
            setViewModalNewCosto(true)
           formCosto.resetFields()
    }

    const goModalNewPago = () =>{
        formPago.resetFields()
        setViewModalNewPago(true)
        formPago.setFieldsValue({"descripcion":"PAGO PROVEEDOR SALIDA PLAN "+myPlan.nombre})
    }

    const listarCostos = () =>{
        setLoading(true)
        api.getBy("costoplan",myPlan.id).then(res=>{
            console.log(res.data)
            setCostos(res.data)
            setLoading(false)

        })
    }

    const listarPagos = () =>{
        api.getBy("pagocostoplan",myPlan.id).then(res=>{
            console.log(res.data)
            setPagos(res.data)
        })
    }

    const listarProveedores = () =>{
        api.get("proveedor").then(res=>{
            console.log(res.data)
            setProveedores(res.data)
        })
    }

    const goEditarCosto = (record) =>{
        setViewEditModalCosto(true)
        formEditCosto.setFieldsValue({"valorPesos":record.valorPesos,"valorDolares":record.valorDolares})
        let findProveedor = proveedores.filter(item=>item.id == record.proveedor_id)[0]
        console.log(findProveedor)
        formEditCosto.setFieldsValue({"proveedor_id":findProveedor.id})

        setCostoSelected(record)
    }

    const saveEditCosto = (values) =>{
        setLoading(true)
        console.log(values)
      
       api.update('costoplan',costoSelected.id,values).then(res=>{
        console.log(res.data)
        listarCostos()
        setViewEditModalCosto(false)
        setLoading(false)
       })
    }

   const  calcularPagos = (costo_id,moneda)=>{
        let total=0;
        for(let item of pagos ){
          if(item.divisa==moneda && item.egreso.estado == "Aprobado" && item.costo_id == costo_id){
            total+=parseFloat(item.valor)
          }
    
        }
        return total;
      }

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })
    
    const columnsCosto = [
        {
        title: 'Codigo',
        render:(record)=> <div>{record.id}</div>
    },
    {
        title: 'Proveedor',
        render:(record)=> <div>{record.proveedor.nombre}</div>
    },
    {
        title: 'Nit',
        render:(record)=> <div>{record.proveedor.nit}</div>
    },
    {
        title: 'Total Costos Pesos',
        render:(record)=> <div>{formatterPeso.format(record.valorPesos)}</div>
    },

    {
        title: 'Total Costos Dolares',
        render:(record)=> <div>{formatterPeso.format(record.valorDolares)}</div>
    },

    {
        title: 'Total Pagos Pesos',
        render:(record)=> <div>{formatterPeso.format(calcularPagos(record.id,"Pesos"))}</div>
    },

    {
        title: 'Total Pagos Dolares',
        render:(record)=> <div>{formatterPeso.format(calcularPagos(record.id,"Dolares"))}</div>
    },

    {
        title: 'Saldo Pesos',
        render:(record)=> <div>{formatterPeso.format(record.valorPesos - calcularPagos(record.id,"Pesos"))}</div>
    },

    {
        title: 'Saldo Dolares',
        render:(record)=> <div>{formatterPeso.format(record.valorDolares - calcularPagos(record.id,"Dolares"))}</div>
    },
    {
        title: 'Opciones',
        render:(record)=> <Button type="primary" onClick={()=>{goEditarCosto(record)}}>Editar</Button>
    }

]


const columnsPago = [{
    title:"Recibo No.",
    render:(record) => <div>{record.egreso.id}</div>
},
{
    title:"Proveedor",
    render:(record) => <div>{record.proveedor.nombre}</div>
},
{
    title:"Valor",
    render:(record) => <div>{formatterPeso.format(record.valor)}</div>
},
{
    title:"Divisa",
    render:(record) => <div>{record.divisa}</div>
},{
    title:"Fecha",
    render:(record) => <div>{moment(record.created_at).format("DD-MM-YYYY")}</div>
},{
    title:"Estado",
    render:(record) => <div>{record.egreso.estado}</div>
}]


const saveCosto = (values)=>{
    setLoading(true)
    let form = values
    form.plan_id = myPlan.id
    api.post("costoplan",form).then(res=>{
        console.log(res.data)
        setViewModalNewCosto(false)
        setLoading(false)
    })
}

const savePago = (values) =>{
    let form = values
let letras = convertidor.NumeroALetras(values.valor,values.divisa)
    form.plan_id = myPlan.id
    form.valorLetras = letras
    console.log(form)
    let proveedor = costos.filter(item=>item.id == values.costo_id)[0]
    let proveedor_id = proveedor.proveedor.id
    form.proveedor_id = proveedor_id;

    api.post("pagocostoplan",form).then(res=>{
        setViewModalNewPago(false)
        listarPagos()
    })
}

if(loading) return <div className='spin'><Spin></Spin></div>
    return (
        <MyLayout>
            <Card>
                <div className='header'>
                <div className='titulo'>Registro de Costos para {myPlan.nombre} </div>
            <div> <strong>Fecha Entrada: </strong> {moment(myPlan.fechaEntrada).format("DD/MM/YYYY")} </div>
            <div> <strong>Fecha Salida: </strong>  {moment(myPlan.fechaSalida).format("DD/MM/YYYY")}</div>
            </div>
            </Card>

      <br />
           <Card>
           <div className='titulo'>Costos </div>
           <div><Button type='primary' onClick={goModalNewCosto}>Crear Costo</Button></div>
             {costos != 'No Existe Costo' && (
            <Table dataSource={costos} columns={columnsCosto}></Table>
            )}
           </Card>
<br />
           <Card>
           <div className='titulo'>Pagos </div>
           <div><Button type='primary' onClick={goModalNewPago}>Crear pago</Button></div>
           <Table dataSource={pagos} columns={columnsPago}></Table>
           </Card>


           {viewModalNewCosto && (
            <Modal visible={viewModalNewCosto}  title="Crear nuevo costo" onCancel={()=>{setViewModalNewCosto(false)}} onOk={formCosto.submit} cancelText="Cancelar" okText="Guardar Costo">
                <Form layout='vertical' form={formCosto} onFinish={saveCosto}>
                    <Form.Item label="Proveedor" name="proveedor_id" rules={[{required:true}]}>
                        <Select showSearch  optionFilterProp="children">
                            {proveedores.map((item)=>{
                                return <Select.Option value={item.id}>{item.nombre} ({item.nit})</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Costo en Pesos" name="valorPesos" rules={[{required:true}]}>
                    <Input></Input>
                    </Form.Item>
                    <Form.Item label="Costo en Dolares" name="valorDolares" rules={[{required:true}]}>
                    <Input></Input>
                    </Form.Item>
                </Form>

            </Modal>
           )}

{viewEditModalCosto && (
            <Modal visible={viewEditModalCosto}  title="Editar costo" onCancel={()=>{setViewEditModalCosto(false)}} onOk={formEditCosto.submit} cancelText="Cancelar" okText="Guardar Costo">
                <Form layout='vertical' form={formEditCosto} onFinish={saveEditCosto}>
                    <Form.Item label="Proveedor" name="proveedor_id" rules={[{required:true}]}>
                        <Select showSearch  optionFilterProp="children">
                            {proveedores.map((item)=>{
                                return <Select.Option value={item.id} key={item.id}>{item.nombre} ({item.nit})</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Costo en Pesos" name="valorPesos" rules={[{required:true}]}>
                    <Input></Input>
                    </Form.Item>
                    <Form.Item label="Costo en Dolares" name="valorDolares" rules={[{required:true}]}>
                    <Input></Input>
                    </Form.Item>
                </Form>

            </Modal>
           )}


{viewModalNewPago && (
            <Modal visible={viewModalNewPago}  title="Crear nuevo pago" onCancel={()=>{setViewModalNewPago(false)}} onOk={formPago.submit} cancelText="Cancelar" okText="Guardar Pago">
                <Form layout='vertical' form={formPago} onFinish={savePago}>
                    <Form.Item label="Costo" name="costo_id" rules={[{required:true}]}>
                        <Select showSearch  optionFilterProp="children">
                            {costos.map((item)=>{
                                return <Select.Option value={item.id}>{item.id} - {item.proveedor.nombre}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Valor" name="valor" rules={[{required:true}]}>
                    <Input></Input>
                    </Form.Item>
                    <Form.Item label="Divisa" name="divisa" rules={[{required:true}]}>

                    <Select>
                    <Select.Option value="Pesos">Pesos</Select.Option>
                    <Select.Option value="Dolares">Dolares</Select.Option>
                    </Select>
                    </Form.Item>

                    <Form.Item label="Forma de Pago" name="formaPago" rules={[{required:true}]}>

                    <Select>
                    <Select.Option value="EFECTIVO">EFECTIVO</Select.Option>
            <Select.Option value="TARJETA">TARJETA</Select.Option>
            <Select.Option value="TRANSFERENCIA">TRANSFERENCIA</Select.Option>
            <Select.Option value="CONSIGNACION">CONSIGNACION</Select.Option>
                    </Select>
                    </Form.Item>

                    <Form.Item label="Descripción" name="descripcion" rules={[{required:true}]}>
                    <TextArea></TextArea>
                    </Form.Item>
                </Form>

            </Modal>
           )}
          

        </MyLayout>
    );
};

export default CostoPlan;