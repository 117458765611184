//import http from "../utils/http-common";
import { useAuth } from '../context/auth.context';
import axios from "axios";
import {  useState } from 'react';

const ApiService = () => {
  

  const http = (axios.create({
    baseURL: window.localStorage.getItem("dominio"),
  headers: {
    "Content-type": "application/json",
    "Authorization": "Bearer "+ window.localStorage.getItem("token")
  }
}))
  

  const get = (route) => {
    
    return http.get(`/${route}`);
  };
  
  const getBy = (route, id) => {
    return http.get(`/${route}/${id}`);
  };

  const getForain = (route, id) => {
    return http.get(`/${route}/forain/${id}`);
  };

  const post = (route, data) => {
    console.log(http)
    return http.post(`/${route}`, data);
  };

  const update = (route, id, data) => {
    return http.put(`/${route}/${id}`, data);
  };

  const remove = (route, id) => {
    return http.delete(`/${route}/${id}`);
  };

  return {
    get,
    getBy,
    getForain,
    post,
    update,
    remove,
  };
};

export default ApiService;