import React, {createContext,useContext, useState} from 'react';

export const authContext = createContext()

export const useAuth = () =>{
    const context = useContext(authContext)
    if(!context) throw new Error("No User")
    return context
}

export function AuthProvider ({children}) {
    //let userTest;
     //let userTest = {"empresa_id": 1, "id": 1,"nombre": "Julian Benavides","password": "Julian123", "username": "julian" }
     const usuario = JSON.parse(window.localStorage.getItem('user'));
     const rol = window.localStorage.getItem('role');
     const tokenLocal = window.localStorage.getItem('token');
     const dominioLocal = window.localStorage.getItem('dominio');
     const sucursalLocal = window.localStorage.getItem('sucursal');
    const [user,setUser] = useState(usuario)
    const [role,setRole] = useState(rol)
    const [token,setToken] = useState(tokenLocal)
    const [dominio,setDominio] = useState(dominioLocal)
    const [sucursal,setSucursal] = useState(sucursalLocal)

    const saveUser = (user) => {
        setUser(user)
        window.localStorage.setItem('user', JSON.stringify(user))
    }

    const saveRole = (role) => {
        setRole(role)
        window.localStorage.setItem('role', role)
    }

    const saveToken = (token) => {
        setToken(token)
        window.localStorage.setItem('token', token)
    }

    const saveDominio = (dominio) => {
        
        setDominio(dominio)
        window.localStorage.setItem('dominio', dominio)
      
    }

    const saveSucursal = (sucursal) => {
        
        setSucursal(sucursal)
        window.localStorage.setItem('sucursal', sucursal)
      
    }


    return (
        <authContext.Provider value={{user,saveUser,role,saveRole,token,saveToken,dominio,saveDominio,sucursal,saveSucursal}}>
            {children}
        </authContext.Provider>
    );
};

