import React from 'react';
import MyLayout from '../../components/shared/MyLayout';
import Navbar from '../../components/shared/Navbar';

const Home = () => {
    return (
        <MyLayout>
          
        </MyLayout>
    );
};

export default Home;