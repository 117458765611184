import './App.css';
import { Routes,Route, HashRouter } from 'react-router-dom'
import Home from './pages/Home/Home';
import Login from './pages/Login/Login'
import { AuthProvider } from './context/auth.context';
import { ProtectedRoute } from './routes/ProtectedRoute';
import Ahorro from './pages/Ahorro/Ahorro';
import Plan from './pages/Plan/Plan';
import CostoPlan from './pages/Plan/CostoPlan';
import Bono from './pages/Bono/Bono';
function App() {
  return (
    <HashRouter>
    <AuthProvider>
        <Routes>
                <Route  path="/login" element={<Login />} />
                <Route  path="/" element={ <ProtectedRoute><Home /></ProtectedRoute> } />
                <Route  path="/ahorro" element={ <ProtectedRoute><Ahorro /></ProtectedRoute> } />
                <Route  path="/planes" element={ <ProtectedRoute><Plan /></ProtectedRoute> } />
                <Route  path="/bonos" element={ <ProtectedRoute><Bono /></ProtectedRoute> } />
                <Route  path="/costos-plan" element={ <ProtectedRoute><CostoPlan /></ProtectedRoute> } />
                </Routes>
      </AuthProvider>
      </HashRouter>
  );
}

export default App;
