import React, { useEffect, useState } from 'react';
import MyLayout from '../../components/shared/MyLayout';
import ApiService from '../../services/api.service';
import { Table, Tooltip, Button,  Input, Modal, Spin, message } from 'antd';
import {StepForwardOutlined, SearchOutlined} from '@ant-design/icons';

const {confirm} = Modal;
const Ahorro = () => {

    const [ahorros,setAhorros] = useState()
    const [loading,setLoading] = useState(false)
    const api = ApiService()

    const columns = [

        {
            title: "No. Ahorro",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
                return <Input autoFocus placeholder='Ingrese No. Ahorro' 
                value = {selectedKeys[0]} 
                onChange={(e)=>{
                  setSelectedKeys(e.target.value?[e.target.value]:[])
                 confirm({closeDropdown:false})
                }}
                onPressEnter={()=>{
                  confirm()
                }} 
                
                onBlur={()=>{
                  confirm()
                }} >
                
                </Input>
              },
              filterIcon:()=>{
                return <SearchOutlined />
              },
              onFilter:(value,record)=>{
                let nombre = record.id.toString()
                return nombre.includes(value.toLowerCase())
              },
            render:(record) => <div>{record.id}</div>
        },

        {
            title: "No. ID",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
                return <Input autoFocus placeholder='Ingrese No. Id' 
                value = {selectedKeys[0]} 
                onChange={(e)=>{
                  setSelectedKeys(e.target.value?[e.target.value]:[])
                 confirm({closeDropdown:false})
                }}
                onPressEnter={()=>{
                  confirm()
                }} 
                
                onBlur={()=>{
                  confirm()
                }} >
                
                </Input>
              },
              filterIcon:()=>{
                return <SearchOutlined />
              },
              onFilter:(value,record)=>{
                let nombre = record.ahorro.numId.toString()
                return nombre.includes(value.toLowerCase())
              },
            render:(record) => <div>{record.ahorro.numId}</div>
        },

        {
            title: "Nombres",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
                return <Input autoFocus placeholder='Ingrese Nombres' 
                value = {selectedKeys[0]} 
                onChange={(e)=>{
                  setSelectedKeys(e.target.value?[e.target.value]:[])
                 confirm({closeDropdown:false})
                }}
                onPressEnter={()=>{
                  confirm()
                }} 
                
                onBlur={()=>{
                  confirm()
                }} >
                
                </Input>
              },
              filterIcon:()=>{
                return <SearchOutlined />
              },
              onFilter:(value,record)=>{
                let nombre = record.ahorro.nombres + " " +record.ahorro.apellidos;
                return nombre.toLowerCase().includes(value.toLowerCase())
              },
            render:(record) => <div>{record.ahorro.nombres} {record.ahorro.apellidos}</div>
        },

          
        {
            title: "Celular",
            render:(record) => <div>{record.ahorro.celular}</div>
        },

          
        {
            title: "Profesión",
            render:(record) => <div>{record.profesion} </div>
        },

        {
            title: "Fecha Apertura",
            render:(record) => <div>{record.apertura} </div>
        },

        {
            title: "Meta",
            render:(record) => <div>{formatterPeso.format(record.meta)} </div>
        },

        {
            title: "Valor Mensual",
            render:(record) => <div>{formatterPeso.format(record.valorAhorro)} </div>
        },

        {
            title: "Valor Pagado",
            render:(record) => <div>{formatterPeso.format(calcularPagos(record.pagos)[0])}</div>
        },

        {
            title: "No. cuotas pactadas",
            render:(record) => <div>{record.cuotas} </div>
        },

        {
            title: "No. cuotas pagadas",
            render:(record) => <div>{calcularPagos(record.pagos)[1]}</div>
        },

        {
            title: "No. cuotas pendientes",
            render:(record) => <div>{parseInt(record.cuotas)-calcularPagos(record.pagos)[1]}</div>
        },

        {
            title: "Saldo a pagar",
            render:(record) => <div>{formatterPeso.format(parseFloat(record.meta)-calcularPagos(record.pagos)[0])}</div>
        },

        {
            title: "Estado",
            render:(record) => <div>{record.estado}</div>
        },

        {
            title: "Opciones",
            render:(record) => <div>
                <Button onClick={()=>{finalizarAhorro(record.id)}}><Tooltip title="Finalizar Ahorro"><StepForwardOutlined /> </Tooltip></Button>
            </div>
        },


        
    ]

    const formatterPeso = new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0
    })

    useEffect(()=>{
      listarAhorros()
    },[])


    const listarAhorros = async () =>{
      
      setLoading(true)
      await api.get('ahorro').then(res=>{
            setAhorros(res.data)
            setLoading(false)
        })
    }

    const finalizarAhorro =  (id) =>{
      confirm({
          title:"Desea finalizar el ahorro?",
          okText:"Si",
          okType:'danger',
          cancelText:"No",
          onOk () {
            setLoading(true);
            api.getBy("ahorro/estado/finalizar",id)
          .then((response) => {
           
            message.success("Ahorro finalizado")
            listarAhorros()
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            message.error("No se ha podido finalizar el ahorro")
            setLoading(false);
          });
          }
        })
    }

    const calcularPagos = (pagos) =>{
        let total = 0
        let contadorPagos = 0
        for(let item of pagos){
            if(item.estado == 2){
                total += parseFloat(item.valorPago)
                contadorPagos ++
            }
        }
        return [total,contadorPagos]
    }

    if(loading) return <div className='spin'><Spin></Spin></div>
    return (
        <MyLayout>
            <h4>Ahorro Programado</h4>

            <Table scroll={{x:1200}} dataSource={ahorros} columns = {columns} pagination={{ pageSize: 5 }} loading={loading} ></Table>

        </MyLayout>
    );
};

export default Ahorro;