
import React, { useEffect, useState, useForm } from 'react';
import MyLayout from '../../components/shared/MyLayout';
import ApiService from '../../services/api.service';
import { Table, Tooltip, Button,  Input, Modal, Spin, message, Select, Form } from 'antd';
import {StepForwardOutlined, SearchOutlined, DollarCircleOutlined} from '@ant-design/icons';
import {  useNavigate } from 'react-router-dom';

const PlanVigente = () => {

    const [planes,setPlanes] = useState()
    const [loading,setLoading] = useState(false)
    const api = ApiService()
    const [tipoPlan,setTipoPlan] = useState("Vigentes")
    const navigate = useNavigate()
    const [formFiltro] = Form.useForm()
    formFiltro.setFieldsValue({"tipoPlanSeleccionado":tipoPlan})

    useEffect(()=>{
        listarPlanes(tipoPlan)
      },[])


      const irCostos = (record) =>{
        navigate("/costos-plan",{ state: { plan: record } });
      }

      const columns = [

        {
            title: "Codigo Plan",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
                return <Input autoFocus placeholder='Ingrese Codigo del Plan' 
                value = {selectedKeys[0]} 
                onChange={(e)=>{
                  setSelectedKeys(e.target.value?[e.target.value]:[])
                 confirm({closeDropdown:false})
                }}
                onPressEnter={()=>{
                  confirm()
                }} 
                
                onBlur={()=>{
                  confirm()
                }} >
                
                </Input>
              },
              filterIcon:()=>{
                return <SearchOutlined />
              },
              onFilter:(value,record)=>{
                let nombre = record.id.toString()
                return nombre.includes(value.toLowerCase())
              },
            render:(record) => <div>{record.id}</div>
        },

        {
            title: "Destino",
            filterDropdown:({setSelectedKeys,selectedKeys,confirm}) => {
                return <Input autoFocus placeholder='Ingrese Destino del Plan' 
                value = {selectedKeys[0]} 
                onChange={(e)=>{
                  setSelectedKeys(e.target.value?[e.target.value]:[])
                 confirm({closeDropdown:false})
                }}
                onPressEnter={()=>{
                  confirm()
                }} 
                
                onBlur={()=>{
                  confirm()
                }} >
                
                </Input>
              },
              filterIcon:()=>{
                return <SearchOutlined />
              },
              onFilter:(value,record)=>{
                let nombre = record.destino
                return nombre.toLowerCase().includes(value.toLowerCase())
              },
            render:(record) => <div>{record.destino}</div>
        },

        {
            title: "Fecha Inicial",
            render:(record) => <div>{record.fechaEntrada} </div>
        },

        {
            title: "Fecha Final",
            render:(record) => <div>{record.fechaSalida} </div>
        },


        {
            title: "Opciones",
            render:(record) => <div>
              <Tooltip title="Registro de Costos del Plan">
                <Button onClick={()=>{irCostos(record)}}>
                  <DollarCircleOutlined /></Button></Tooltip>
            </div>
        },


        
    ]

    function sortByDestino(data) {
        data.sort((a, b) => {
          if (a.destino < b.destino) {
            return -1;
          }
          if (a.destino > b.destino) {
            return 1;
          }
          return 0;
        });
        return data;
      }

      const reviewTipoPlan = (e) =>{
        console.log(e)
        setTipoPlan(e)
        listarPlanes(e)
      }

      const listarPlanes = async (tipoPlan) =>{
        setLoading(true)
        if(tipoPlan == "Vigentes"){
        await api.get('plan').then(res=>{
            let planesVigentes = res.data.filter(item=>item.estadoPlan!="Eliminado");
            let planesOrdenados = sortByDestino(planesVigentes)
              setPlanes(planesOrdenados)
              console.log(planesVigentes)
              setLoading(false)
          })
        }

        if(tipoPlan == "Pasados"){
          await api.get('plan/buscar/pasados').then(res=>{
              let planesPasados = res.data.filter(item=>item.estadoPlan!="Eliminado");
              let planesOrdenados = sortByDestino(planesPasados)
                setPlanes(planesOrdenados)
                console.log(planesPasados)
                setLoading(false)
            })
          }
      }
      if(loading) return <div className='spin'><Spin></Spin></div>
    return (
        <MyLayout>

        <Form form={formFiltro}>
          <Form.Item name="tipoPlanSeleccionado" label="Filtrar">
          <Select onChange={reviewTipoPlan}>
            <Select.Option value="Vigentes">Vigentes</Select.Option>
            <Select.Option value="Pasados">Pasados</Select.Option>
          </Select>
          </Form.Item>
          </Form>
            <Table columns={columns} dataSource={planes}></Table>
        </MyLayout>
    );
};

export default PlanVigente;