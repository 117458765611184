import { Form, Input, Button, Select, Spin, message } from 'antd';
import React, { useState } from 'react';
import "./Login.css"
import { useAuth } from '../../context/auth.context';
import ApiService from '../../services/api.service'
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate()
  const api = ApiService()
  const {saveUser,saveRole,saveDominio, saveToken,saveSucursal} = useAuth()
  const [loading,setLoading] = useState(false)
  const [dominio,setDominio] = useState(false)
  const [sucursal,setSucursal] = useState(false)

  const sendDominio = (e) =>{
    let urlApi;
      if(e == "Agencia principal"){
      urlApi = "https://agenciapi.turismoandinopasto.com.co"
     //urlApi = "https://mocoapi.turismoandinopasto.com"
    }

    if(e == "Agencia pasadías"){
      urlApi = "https://comercialapi.turismoandinopasto.com.co"
    }

    if(e == "Agencia Ecuador"){
      urlApi = "https://apiecuador.turismoandino.ec"
    }

    console.log(urlApi)
    setDominio(urlApi)
    setSucursal(e)
   
  }

  const onFinish = async (values) =>{
    saveDominio(dominio)
    saveSucursal(sucursal)
    setLoading(true)
    const res = await api.post('login',values).then(res=>{
        console.log(res.data)
        const {id,nombre,role,token} = res.data
        saveUser({id,nombre})
        saveRole(role)
        saveToken(token)
       
        
        navigate("/")
        message.success("Bienvenido")
      }).catch(error=>{
        message.error("No existe el usuario")
      }).finally(() => {
        setLoading(false);
      });
    
   
  }

    if(loading) return <div className='spin'><Spin></Spin></div>
    return (
      <div className='login'>
      <Form layout='vertical' className='form-login'  autoComplete="off" onFinish={onFinish}>
        <div>
        <img className='logo-inicial' src="./assets/img/logo.png" alt="" />
        </div>
       <Form.Item label="Correo" name="email" rules={[{required:true}]}>
        <Input type="text" placeholder='correo' />
       </Form.Item>

       <Form.Item label="Password" name="password" rules={[{required:true}]} >
        <Input type="password" placeholder='password' />
       </Form.Item>

       <Form.Item label="Dominio" name="dominio" rules={[{required:true}]}  >
       <Select onChange={(e)=>{sendDominio(e)}}>
        <Select.Option value="Agencia principal">Agencia principal</Select.Option>
        <Select.Option value="Agencia pasadías">Agencia pasadías</Select.Option>
        <Select.Option value="Agencia Ecuador">Agencia Ecuador</Select.Option>
       </Select>
       </Form.Item>

       <Form.Item >
       <Button type='primary' htmlType="submit">Iniciar</Button>
       </Form.Item>
      </Form>
      </div>

    );
};

export default Login;